<template>
    <div>
        <CustomHeader title="售票监控" @change="getData"></CustomHeader>
        <div class="layout">
        <el-row :gutter="20">
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">今日和昨日入园客流</div>
                        <div class="item-content">
                            <div class="item-sub">
                                <div class="item-subtitle">今日入园</div>
                                <dv-digital-flop :config="{number:[list.ticket_count.now.checkNum],content:'{nt}人次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">昨日入园</div>
                                <dv-digital-flop :config="{number:[list.ticket_count.two.checkNum],content:'{nt}人次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="12">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">近30天与前30天客流对比</div>
                        <div class="item-content">
                            <eCharts :options="option1"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">景区榜单</div>
                        <div class="item-content">
                            <div class="item-sub">
                                <div class="item-subtitle">崆峒山</div>
                                <dv-digital-flop :config="{number:[list.ticket_count.now.checkNum],content:'{nt}人次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">月售票实时统计</div>
                        <div class="item-content">
                            <div class="item-sub">
                                <div class="item-subtitle">本月售票量</div>
                                <dv-digital-flop :config="{number:[list.ticket_count.now_month.checkNum],content:'{nt}人次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">上月售票量</div>
                                <dv-digital-flop :config="{number:[list.ticket_count.two_month.checkNum],content:'{nt}人次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="12">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">节假日客流统计</div>
                        <div class="item-content">
                            <eCharts :options="option2"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">每人次购票量统计</div>
                        <div class="item-content">
                            <eCharts :options="option3"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">年售票实时统计</div>
                        <div class="item-content">
                            <div class="item-sub">
                                <div class="item-subtitle">今年累计售票量</div>
                                <dv-digital-flop :config="{number:[list.ticket_count.now_year.checkNum],content:'{nt}人次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">去年累计售票量</div>
                                <dv-digital-flop :config="{number:[list.ticket_count.two_year.checkNum],content:'{nt}人次',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">每天各时间段售票平均值</div>
                        <div class="item-content">
                            <eCharts :options="option4"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">每天各时间段售票平均值</div>
                        <div class="item-content">
                            <eCharts :options="option5"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">游客线上购票来源</div>
                        <div class="item-content">
                            <dv-scroll-ranking-board :config="option6" style="width:85%;height:80%" />
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
        </el-row>
    </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import {getTicketInfo} from "../api/common";
    import eCharts from "../components/echarts"
    import CustomHeader from "../components/CustomHeader";
    export default {
        name: "Tickets",
        components:{eCharts,CustomHeader},
        computed:{
            ...mapState(['linear_color','echarts_color1','echarts_color2','echarts_color3','echarts_color4','echarts_color5'])
        },
        data(){
            return{
                list:{
                    ticket_count:{
                        now:{
                            checkNum:0,
                            saleNum:0
                        },
                        two:{
                            checkNum:0,
                            saleNum:0
                        },
                        year:{
                            checkNum:0,
                            saleNum:0
                        },
                        now_month:{
                            checkNum:0,
                            saleNum:0
                        },
                        two_month:{
                            checkNum:0,
                            saleNum:0
                        },
                        now_year:{
                            checkNum:0,
                            saleNum:0
                        },
                        two_year:{
                            checkNum:0,
                            saleNum:0
                        }
                    }
                },
                option1:{},//近30天与前30天客流对比
                option2:{},//节假日客流统计
                option3:{},//每人次购票量统计
                option4:{},//每天各市局售票平均值
                option5:{},//未来一周客流量预测
                option6:{},//游客线上购票来源
                option1_index:0,
                option1_list:[]
            }
        },
        mounted() {
            this.getData()
            setInterval(()=>{
                this.getData()
            },60*1000)
            this.changeDate()
        },
        methods:{
            async getData(e){
                let res=await getTicketInfo(e)
                this.list=res.data
                console.log(res.data)
                this.option1 = {
                    baseOption:{
                        dataZoom: [//给x轴设置滚动条
                            {
                                type: 'inside',
                                realtime: true,
                                startValue: 0,
                                endValue: 10
                            }
                        ],
                            tooltip: {
                            trigger: 'axis',
                                axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                            }
                        },
                        legend: {
                            data: ['近30天', '前30天'],
                                textStyle: {
                                color: 'white',
                                    fontSize: 14
                            },
                        },
                        grid: {
                                top:'20%',
                                left: '5%',
                                right: '7%',
                                bottom:'0',
                               containLabel: true,
                        },
                        xAxis: {
                            type: 'category',
                                axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            boundaryGap: false,
                                data: res.data['ticket_30']['time'],
                        },
                        yAxis: {
                            splitNumber: 3,
                                type: 'value',
                                axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14,
                            },
                            splitLine:{//分割线
                                show:false
                            }
                        },
                        series: [{
                            name: '近30天',
                            type: 'line',
                            areaStyle: {},
                            data: res.data['ticket_30']['now'],
                            label:{
                                show:true
                            }
                        }],
                        textStyle: {
                            color: 'white'
                        },
                        color: this.linear_color
                    },
                };
                this.option2 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                            }
                        },
                        legend: {
                            data: ['游客人次'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            }
                        },
                        grid: {
                            top:'20%',
                            left: '5%',
                            right: '5%',
                            bottom:'0',
                            containLabel: true
                        },
                        xAxis: [{
                            type: 'category',
                            data: ['元旦', '春节', '清明', '五一', '端午','中秋','十一'],
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                        }],
                        yAxis: [{
                            type: 'value',
                            splitNumber: 1,
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            splitLine:{//分割线
                                show:false
                            }
                        }],
                        series: [{
                            name: '游客人次',
                            type: 'bar',
                            stack: '游客人次',
                            barWidth: '25px',
                            label: {
                                show: true,
                                position: 'top'
                            },
                            data: [res.data['holiday']['yuandan'], res.data['holiday']['chunjie'], res.data['holiday']['qingming'], res.data['holiday']['wuyi'], res.data['holiday']['duanwu'], res.data['holiday']['zhongqiu'],res.data['holiday']['guoqing']],
                            color: this.linear_color
                        }, ],
                        textStyle: {
                            color: 'white'
                        },
                    },
                };
                this.option3 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: { // 坐标轴指示器，坐标轴触发有效
                                type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
                            }
                        },
                        legend: {
                            data: ['购票人次'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            }
                        },
                        grid: {
                            top:'20%',
                            left: '5%',
                            right: '5%',
                            bottom:'0',
                            containLabel: true
                        },
                        xAxis: [{
                            type: 'category',
                            data: ['1张', '2张', '3张', '4张', '5张', '多于5张'],
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                        }],
                        yAxis: [{
                            type: 'value',
                            splitNumber: 1,
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            splitLine:{//分割线
                                show:false
                            }
                        }],
                        series: [{
                            name: '购票人次',
                            type: 'bar',
                            stack: '购票人次',
                            barWidth: '25px',
                            data: Object.values(res.data['ticket_user_number']),
                            color: this.linear_color,
                            label: {
                                show: true,
                                position: 'top'
                            },
                        }, ],
                        textStyle: {
                            color: 'white'
                        },
                    },
                };
                this.option4 = {
                    baseOption:{
                        dataZoom: [//给x轴设置滚动条
                            {
                                type: 'inside',
                                realtime: true,
                                startValue: 0,
                                endValue: 10
                            }
                        ],
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['售票量'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            },
                        },
                        grid: {
                            top:'20%',
                            left: '9%',
                            right: '9%',
                            bottom:'0',
                            containLabel: true,
                        },
                        xAxis: {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            boundaryGap: false,
                            data: res.data['time_change']['sale']['hour'],
                        },
                        yAxis: {
                            splitNumber: 3,
                            type: 'value',
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            splitLine:{//分割线
                                show:false
                            }
                        },
                        series: [{
                            name: '售票量',
                            type: 'line',
                            stack: '总量',
                            areaStyle: {},
                            data: res.data['time_change']['sale']['number'],
                            label:{
                                show:true
                            },
                            color: this.linear_color
                        }],
                        textStyle: {
                            color: 'white'
                        },
                    },
                };
                this.option5 = {
                    dataZoom: [//给x轴设置滚动条
                        {
                            type: 'inside',
                            realtime: true,
                            startValue: 0,
                            endValue: 10
                        }
                    ],
                    baseOption:{
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['客流量'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            },
                        },
                        grid: {
                            top:'20%',
                            left: '9%',
                            right: '14%',
                            bottom:'0',
                            containLabel: true,
                        },
                        xAxis: {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            boundaryGap: false,
                            data: res.data['time_change']['check']['hour'],
                        },
                        yAxis: {
                            splitNumber: 3,
                            type: 'value',
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            splitLine:{//分割线
                                show:false
                            }
                        },
                        series: [{
                            name: '客流量',
                            type: 'line',
                            stack: '总量',
                            areaStyle: {},
                            data: res.data['time_change']['check']['number'],
                            label:{
                                show:true
                            },
                            color: this.linear_color
                        }],
                        textStyle: {
                            color: 'white'
                        },
                    },
                };
                this.option6 ={
                    data:res.data['ticket_from'].map(item=> {
                        if(item.name.length>10){
                            item.name=item.name.substr(0, 10)+'..'
                        }
                        return item
                    }),
                    rowNum:3,
                    sort:true,
                }
                this.option1_list=[{
                    name: '近30天',
                    type: 'line',
                    areaStyle: {},
                    data: res.data['ticket_30']['now'],
                    label:{
                        show:true
                    }
                },
                {
                     name: '前30天',
                     type: 'line',
                     areaStyle: {},
                     data: res.data['ticket_30']['two'],
                     label:{
                         show:true
                     }
                }];
            },
            changeDate(){
				
				return true;
				
                setInterval(()=>{
                    if(this.option1_index<this.option1_list.length-1){
                        this.option1_index++
                    }
                    else{
                        this.option1_index=0
                    }
                    this.option1.baseOption.series=this.option1_list[this.option1_index]
                },5000)
            },
            formatter(number) {
                const numbers = number.toString().split('').reverse()
                const segs = []
                while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
                return segs.join(',').split('').reverse().join('')
            }
        }
    }
</script>

<style scoped lang="scss">
@import "src/static/css/common";
</style>
